// Load Style Code
import 'tui-image-editor/dist/tui-image-editor.css'
import 'tui-color-picker/dist/tui-color-picker.css'
import { ImageEditor } from '@toast-ui/vue-image-editor'
import Vue from 'vue'

const icona = require('tui-image-editor/dist/svg/icon-a.svg')
const iconb = require('tui-image-editor/dist/svg/icon-b.svg')
const iconc = require('tui-image-editor/dist/svg/icon-c.svg')
const icond = require('tui-image-editor/dist/svg/icon-d.svg')
const theme = {
  'common.bisize.width': '251px',
  'common.bisize.height': '21px',
  'common.backgroundColor': '#fff',
  'common.border': '1px solid #c1c1c1',

  // main icons
  'menu.normalIcon.path': icond,
  'menu.normalIcon.name': 'icon-d',
  'menu.activeIcon.path': iconb,
  'menu.activeIcon.name': 'icon-b',
  'menu.disabledIcon.path': icona,
  'menu.disabledIcon.name': 'icon-a',
  'menu.hoverIcon.path': iconc,
  'menu.hoverIcon.name': 'icon-c',
  'menu.iconSize.width': '15px',
  'menu.iconSize.height': '15px',

  // header
  'header.backgroundImage': 'none',
  'header.backgroundColor': 'transparent',
  'header.border': '0px',
  'header.display': 'none',

  // submenu primary color
  'submenu.backgroundColor': 'transparent',
  'submenu.partition.color': '#e5e5e5',

  // submenu icons
  'submenu.normalIcon.path': icond,
  'submenu.normalIcon.name': 'icon-d',
  'submenu.activeIcon.path': iconb,
  'submenu.activeIcon.name': 'icon-b',
  'submenu.iconSize.width': '10px',
  'submenu.iconSize.height': '10px',

  // submenu labels
  'submenu.normalLabel.color': '#858585',
  'submenu.normalLabel.fontWeight': 'normal',
  'submenu.activeLabel.color': '#000',
  'submenu.activeLabel.fontWeight': 'normal',

  // checkbox style
  'checkbox.border': '1px solid #ccc',
  'checkbox.backgroundColor': '#fff',

  // rango style
  'range.pointer.color': '#333',
  'range.bar.color': '#ccc',
  'range.subbar.color': '#606060',

  'range.disabledPointer.color': '#d3d3d3',
  'range.disabledBar.color': 'rgba(85,85,85,0.06)',
  'range.disabledSubbar.color': 'rgba(51,51,51,0.2)',

  'range.value.color': '#000',
  'range.value.fontWeight': 'normal',
  'range.value.fontSize': '11px',
  'range.value.border': '0',
  'range.value.backgroundColor': '#f5f5f5',
  'range.title.color': '#000',
  'range.title.fontWeight': 'lighter',

  // colorpicker style
  'colorpicker.button.border': '0px',
  'colorpicker.title.color': '#000'
}

export default {
  name: 'markupEditor',
  data () {
    return {
      showMarkupEditor: false,
      useDefaultUI: false,
      cropping: false,
      loading: false,
      drawing: false,
      title: '',
      file: null,
      options: {
        includeUI: {
          theme: theme,
          menuBarPosition: 'bottom',
          menu: [
            'rotate',
            'shape'
          ]
        },
        cssMaxWidth: document.documentElement.clientWidth,
        cssMaxHeight: document.documentElement.clientHeight / 2.5,
        selectionStyle: {
          cornerSize: 50,
          rotatingPointOffset: 100
        }
      }
    }
  },
  components: {
    'tui-image-editor': ImageEditor
  },
  props: [''],
  computed: {
  },
  methods: {
    getOptions () {
      return this.options
    },
    loadDataFileToCropper (file) {
      const scope = this
      scope.file = file
      scope.loading = false
      scope.showMarkupEditor = true
      Vue.nextTick(function () {
        // scope.$refs.editor.editorInstance.ui._actions.main.load(file)
        scope.$refs.editor.editorInstance.loadImageFromFile(file).then((sizeValue) => {
          if (scope.$refs.editor.editorInstance.ui) {
            scope.$refs.editor.editorInstance.ui.activeMenuEvent()
            scope.$refs.editor.editorInstance.clearUndoStack()
            scope.$refs.editor.editorInstance.ui.resizeEditor({
              imageSize: sizeValue
            })
          }
        }).catch(e => {
          console.error('Something went wrong:')
          console.error(e)
        })
        // scope.redrawCanvas()
      })
    },
    async emitImage () {
      this.loading = true
      // This is because if you don't sleep, the editor base64 to url will
      // block without showing loader!!
      await this.sleep(100)
      const base64 = this.$refs.editor.editorInstance.toDataURL()
      this.$emit('file-processed', { file: this.file, base64: base64 })
      this.showMarkupEditor = false
    },
    rotate (angle) {
      this.$refs.editor.editorInstance.rotate(angle)
    },
    sleep (ms) {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve()
        }, ms)
      })
    },
    undo () {
      this.$refs.editor.editorInstance.undo()
    },
    startCropping () {
      this.cropping = true
      this.title = 'Cropping'
      this.$refs.editor.editorInstance.startDrawingMode('CROPPER')
    },
    applyCrop () {
      const scope = this
      scope.$refs.editor.editorInstance.crop(scope.$refs.editor.editorInstance.getCropzoneRect()).then(() => {
        scope.cancelCrop()
      })
    },
    cancelCrop () {
      this.cropping = false
      this.title = ''
      this.$refs.editor.editorInstance.stopDrawingMode()
    },
    cancelDraw () {
      this.drawing = false
      this.title = ''
      this.$refs.editor.editorInstance.stopDrawingMode()
    },
    draw () {
      this.title = 'Image Markup'
      const shapeOptions = {}
      shapeOptions.stroke = '#FF0000'
      shapeOptions.fill = 'transparent'
      this.drawing = true
      shapeOptions.strokeWidth = 5
      this.$refs.editor.editorInstance.startDrawingMode('FREE_DRAWING', {
        width: 10,
        color: 'rgba(44, 102, 110, 0.7)'
      })
    }
  }
}
