import Vue from 'vue'
import confirm from '../confirm/confirm.vue'
import { Storage } from 'aws-amplify'
import VeeValidate from 'vee-validate'
import uuid from 'uuid/v1'
import imageCompression from 'browser-image-compression'
import ImageMarkup from '../imageMarkup/imageMarkup.vue'
import * as markerjs2 from 'markerjs2'
import moment from 'moment'
import { EventBus } from '../events/eventBus'

Vue.use(VeeValidate)

export default {
  name: 'issue',
  components: {
    confirm: confirm,
    'image-markup': ImageMarkup,
    markerjs2: markerjs2
  },
  data: () => ({
    edit: false,
    dialog: true,
    loading: true,
    sendIssueLoading: false,
    loadingMessages: false,
    imageDialog: false,
    imageSrc: '',
    fileProgress: 0,
    files: [],
    fileUploading: false,
    fileAttached: false,
    message: '',
    hubId: '',
    isMarkUp: false,
    editIssue: {}
  }),

  computed: {
    issueHub () {
      return this.$store.getters['hubs/issueHub']
    },
    selectedPlot () {
      return this.$store.getters['areas/selectedArea']
    },
    showIssue () {
      return this.$store.getters['issues/showIssue']
    },
    areas () {
      return this.$store.getters['areas/areas']
    },
    issue () {
      return this.$store.getters['issues/selectedIssue']
    },
    locations () {
      return this.$store.getters['config/getSelectedLookup']('location')
    },
    internals () {
      return this.$store.getters['config/getSelectedLookup']('internal')
    },
    externals () {
      return this.$store.getters['config/getSelectedLookup']('external')
    },
    messages () {
      const result = this.$store.getters['issues/issueMessages'].reduce((groups, issue) => {
        const date = new Date(issue.createdAt)
        if (!groups[date.toDateString()]) {
          groups[date.toDateString()] = []
        }
        groups[date.toDateString()].push(issue)
        return groups
      }, {})

      return result
    },
    isFixer () {
      return this.$store.getters.getCognitoGroup('fixer')
    }
  },

  async mounted () {
    await this.$store.dispatch('issues/retrieveIssue', this.$route.params.id)
    await this.$store.dispatch('areas/retrieveArea', this.issue.areaId)
    await this.getMessages()
    await this.getIssueImages()

    EventBus.$on('newMessage', this.newMessage)
    EventBus.$on('issueUpdate', this.issueUpdate)
  },

  beforeDestroy () {
    this.dialog = false
    EventBus.$off('newMessage')
    EventBus.$off('issueUpdate')
    this.$store.commit('issues/setSelectedIssue', null)
  },

  methods: {
    issueUpdate (issue) {
      console.log('issue')
      console.log(issue)
      if (issue.id === this.issue.id) {
        console.log('issue UPDATE')
        this.issue.status = issue.status
        // this.$store.commit('issues/setSelectedIssue', issue)
        // this.getIssueImages()
      }
    },
    newMessage (message) {
      if (message.issueId === this.issue.id) {
        this.$store.commit('issues/addIssueMessages', message)
      }
    },
    getAreas: async function () {
      if (this.areas == null || this.areas.length === 0) {
        this.loading = true
      }
      try {
        await this.$store.dispatch('areas/retrieveAreas')
      } catch (err) {
        this.$snotify.error(err.message)
      } finally {
        this.loading = false
      }
    },
    createdAt (issue) {
      return moment.utc(issue.createdAt).local().format('HH:mm')
    },
    async getMessages () {
      this.loadingMessages = true
      try {
        await this.$store.dispatch('issues/retrieveIssueMessages', this.issue.id)
        this.getMessageImages()
      } catch (err) {
        console.log(err)
      } finally {
        this.loadingMessages = false
        this.loading = false
      }
    },
    iconType: function (type) {
      switch (type) {
        case 'message':
          return 'message'
        case 'image':
          return 'photo'
        case 'system':
          return 'settings'
      }
    },
    getName (message) {
      if (message.type && message.type === 'system') {
        return 'System'
      } else {
        return message.name
      }
    },
    async s3ThumbSource (img) {
      if (img.thumb_key) {
        const item = await Storage.get(img.thumb_key, {
          level: img.level
        })
        return item
      }
      return ''
    },
    async s3Source (img) {
      const item = await Storage.get(img.key, {
        level: img.level
      })
      return item
    },
    async getIssueImages () {
      const scope = this
      if (scope.issue && scope.issue.filesJSON && scope.issue.filesJSON.length > 0 && scope.edit === false) {
        for (let i = 0; i < scope.issue.filesJSON.length; i++) {
          const file = scope.issue.filesJSON[i]
          const url = await scope.s3Source(file)
          scope.$set(scope.issue.filesJSON[i], 'url', url)
        }
      }
      if (scope.editIssue && scope.editIssue.filesJSON && scope.editIssue.filesJSON.length > 0 && scope.edit === true) {
        for (let i = 0; i < scope.editIssue.filesJSON.length; i++) {
          const file = scope.editIssue.filesJSON[i]
          const url = await scope.s3Source(file)
          scope.$set(scope.editIssue.filesJSON[i], 'url', url)
        }
      }
    },
    async getMessageImages () {
      const scope = this
      Object.keys(this.messages).forEach(async function (key) {
        const messagesArray = scope.messages[key]
        for (let i = 0; i < messagesArray.length; i++) {
          const message = messagesArray[i]
          for (let j = 0; j < message.filesJSON.length; j++) {
            const file = message.filesJSON[j]
            const url = await scope.s3Source(file)
            scope.$set(messagesArray[i].filesJSON[j], 'url', url)
          }
        }
      })
    },
    pickFile () {
      this.$refs.file.click()
    },
    readFile (file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = e => resolve(e.target.result)
        reader.readAsDataURL(file)
      })
    },
    createImage (data) {
      return new Promise(resolve => {
        const img = document.createElement('img')
        img.onload = () => resolve(img)
        img.src = data
      })
    },
    rotate (type, img) {
      return new Promise(resolve => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0, img.width, img.height)
        canvas.toBlob(resolve, type)
      })
    },
    async uploadFile (processedFile) {
      const scope = this
      const guid = uuid()
      this.fileUploading = { upload_percentage: 0, local_img: processedFile }

      Storage.put('issues/' + guid + processedFile.name, processedFile, {
        level: 'public',
        progressCallback (progress) {
          scope.fileUploading.upload_percentage = Math.round((progress.loaded / progress.total) * 100)
        }
      })
        .then(async function (result) {
          scope.files.push({ level: 'public', key: 'issues/' + guid + processedFile.name })
          await scope.sendMessage()
          scope.fileUploading = null
        })
        .catch(function (err) {
          scope.fileUploading = null
          scope.$snotify.error(err.message, {
            timeout: 15000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          })
        })
    },
    async uploadNewImageFile (processedFile, blob) {
      const scope = this
      const guid = uuid()

      const objectURL = URL.createObjectURL(blob)

      this.fileUploading = { upload_percentage: 0, local_img: objectURL }

      // Resize file if it's bigger than opts here.
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        maxIterations: 2
      }

      imageCompression(processedFile, options).then(function (compressedFile) {
        Storage.put('issues/' + guid + processedFile.name, compressedFile, {
          level: 'public',
          progressCallback (progress) {
            scope.fileUploading.upload_percentage = Math.round((progress.loaded / progress.total) * 100)
            scope.getIssueImages()
          }
        })
          .then(function (result) {
            scope.editIssue.filesJSON.push({ level: 'public', key: 'issues/' + guid + processedFile.name, thumb_key: 'thumbnails/' + guid + processedFile.name, thumb_url: scope.fileUploading.local_img })
            scope.fileUploading = null
            scope.getIssueImages()
          })
          .catch(function (err) {
            scope.fileUploading = null
            scope.$snotify.error(err.message, {
              timeout: 15000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true
            })
          })
      })
    },
    async uploadFileMarkUp (processedFile, imageIndex) {
      console.log(processedFile)
      const scope = this
      const guid = uuid()
      this.fileUploading = { upload_percentage: 0, local_img: processedFile }

      Storage.put('issues/' + guid + processedFile.name, processedFile, {
        level: 'public',
        progressCallback (progress) {
          scope.fileUploading.upload_percentage = Math.round((progress.loaded / progress.total) * 100)
        }
      })
        .then(function (result) {
          scope.editIssue.filesJSON.splice(imageIndex, 1)
          scope.editIssue.filesJSON.push({ level: 'public', key: 'issues/' + guid + processedFile.name, thumb_key: 'thumbnails/' + guid + processedFile.name, thumb_url: scope.fileUploading.local_img })
          scope.fileUploading = null
          scope.getIssueImages()
        })
        .catch(function (err) {
          scope.fileUploading = null
          scope.$snotify.error(err.message, {
            timeout: 15000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          })
        })
    },
    async getBlob (type, data) {
      return fetch(data).then(r => r.blob())
    },
    updateIssue () {
      const issue = {
        id: this.issue.id,
        title: this.issue.description,
        filesJSON: this.issue.filesJSON,
        status: this.issue.status
      }
      const scope = this
      this.$store.dispatch('issues/updateIssue', issue)
        .then(function () {
          scope.loading = false
          scope.$store.dispatch('issues/retrieveIssues')
          scope.$snotify.success('Issue Image Updated', {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          })
        })
        .catch(function (err) {
          scope.$snotify.error(err.message, {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          })
          scope.loading = false
        })
    },
    onFilePicked (e) {
      // const scope = this
      const file = e.target.files[0]
      const scope = this
      scope.readFile(file)
        .then(scope.createImage)
        .then(scope.rotate.bind(undefined, file.type))
        .then(blob => {
          const blobFile = new File([blob], file.name, { lastModified: Date.now() })
          this.uploadFile(blobFile)
        })
    },
    onImagePick (e) {
      // const scope = this
      const file = e.target.files[0]
      const scope = this
      scope.readFile(file)
        .then(scope.createImage)
        .then(scope.rotate.bind(undefined, file.type))
        .then(blob => {
          // this.$refs.imageMarkup.loadDataFileToCropper(blobFile)
          this.uploadNewImageFile(file, blob)
        })
    },
    showMarkerArea (ref, imageIndex) {
      // create a marker.js MarkerArea
      const imageElement = document.getElementById(ref)
      const markerArea = new markerjs2.MarkerArea(imageElement)
      markerArea.settings.displayMode = 'popup'
      // attach an event handler to assign annotated image back to our image element
      markerArea.addRenderEventListener((dataUrl, state) => {
        const dataUrlBlob = this.dataURItoBlob(dataUrl)
        this.uploadFileMarkUp(dataUrlBlob, imageIndex)
        this.isMarkUp = false
        imageElement.src = dataUrl
      })
      markerArea.addCloseEventListener(data => {
        this.isMarkUp = false
      })
      // launch marker.js
      markerArea.show()
      this.isMarkUp = true
    },
    dataURItoBlob (dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      const byteString = atob(dataURI.split(',')[1])

      // separate out the mime component
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length)

      // create a view into the buffer
      const ia = new Uint8Array(ab)
      // set the bytes of the buffer to the correct values
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      // write the ArrayBuffer to a blob, and you're done
      const blob = new Blob([ab], { type: mimeString })
      return blob
    },
    createdAtMessage (message) {
      return moment.utc(message.createdAt).local().format('HH:mm')
    },
    timelineDate (date) {
      return moment(date).format('ll')
    },
    setIssueProperty (prop, value) {
      // this.$store.commit('issues/setDataJSON', prop, value)
      this.$set(this.issue.dataJSON, prop, value)
    },
    exitIssue () {
      if (this.$store.getters['session/getCognitoGroup']('user')) {
        this.$router.push('/issues')
      } else {
        if (this.selectedPlot && this.selectedPlot.id) {
          this.$router.push(`/developments/${this.selectedPlot.id}/issues`)
        } else {
          this.$router.push('/developments')
        }
      }
    },
    closeIssue: function () {
      this.$refs.confirm.open('Close Issue', this.$t('Close Issue'), { color: 'primary' }).then((confirm) => {
        if (confirm) {
          const issue = {
            id: this.issue.id,
            title: this.issue.description,
            status: 'closed'
          }
          const scope = this
          this.$store.dispatch('issues/updateIssue', issue)
            .then(function () {
              scope.loading = false
              scope.issue.status = 'closed'
              scope.$store.dispatch('issues/retrieveIssues')
              scope.$snotify.success('Issue Closed', {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              })
            })
            .catch(function (err) {
              scope.$snotify.error(err.message, {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              })
              scope.loading = false
            })
        }
      })
    },
    completeIssue: function () {
      this.$refs.confirm.open('Complete Issue', this.$t('Complete Issue'), { color: 'primary' }).then((confirm) => {
        if (confirm) {
          const issue = {
            id: this.issue.id,
            title: this.issue.description,
            status: 'work_completed'
          }
          const scope = this
          this.$store.dispatch('issues/updateIssue', issue)
            .then(function () {
              scope.loading = false
              scope.issue.status = 'work_completed'
              scope.$store.dispatch('issues/retrieveIssues')

              scope.$snotify.success('Issue Marked Complete', {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              })
            })
            .catch(function (err) {
              scope.$snotify.error(err.message, {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              })
              scope.loading = false
            })
        }
      })
    },
    async sendIssue () {
      const scope = this
      scope.loading = true
      try {
        await scope.$store.dispatch('issues/updateIssue', scope.issue)
      } catch (err) {
        console.log(err)
        scope.$snotify.error(err.message, {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        })
        scope.loading = false
      } finally {
        scope.loading = true
      }
    },
    async updateIssueInfo () {
      this.$refs.confirm.open('Update Issue', this.$t('Save Changes ?'), { color: 'primary' }).then((confirm) => {
        if (confirm) {
          const scope = this
          scope.editIssue.dataJSON.location = scope.issue.dataJSON.location
          scope.editIssue.dataJSON.internal = scope.issue.dataJSON.internal
          scope.editIssue.dataJSON.external = scope.issue.dataJSON.external
          if (scope.editIssue.dataJSON.location === 'EXT') {
            scope.editIssue.dataJSON.internal = ''
          } else {
            scope.editIssue.dataJSON.external = ''
          }
          const updatedIssue = scope.editIssue
          this.$store.dispatch('issues/updateIssue', updatedIssue)
            .then(function () {
              scope.loading = false
              scope.$snotify.success('Issue Updated Successfully', {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              })
              scope.$store.dispatch('issues/retrieveIssues')
              scope.edit = false
              scope.$nextTick(() => {
                scope.getIssueImages()
              })
            })
            .catch(function (err) {
              scope.$snotify.error(err.message, {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              })
              scope.loading = false
            })
        }
      })
    },
    startEdit () {
      this.edit = !this.edit
      this.editIssue = JSON.parse(JSON.stringify(this.$store.getters['issues/selectedIssue']))
      this.$nextTick(() => {
        this.getIssueImages()
      })
    },
    cancelEdit () {
      this.edit = false
    },
    removeIcon (index) {
      this.editIssue.filesJSON.splice(index, 1)
    },
    showViewImageDialog (imgSrc) {
      this.imageSrc = imgSrc
      this.imageDialog = true
    },
    async sendMessage () {
      const message = {
        message: this.message,
        issueId: this.issue.id,
        filesJSON: this.files
      }

      if (this.files && this.files.length === 0) {
        message.type = 'message'
      } else {
        message.type = 'image'
      }
      const scope = this
      scope.loadingMessages = true
      try {
        const createdMessage = await this.$store.dispatch('issues/createMessage', message)
        console.log(createdMessage)
        scope.$store.commit('issues/addIssueMessages', createdMessage)
        scope.message = ''
        scope.fileAttached = false
        scope.files = []

        if (message.type === 'image') {
          this.getMessageImages()
        }
      } catch (err) {
        scope.$snotify.error(err.message, {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        })
      } finally {
        scope.loadingMessages = false
      }
    }
  }
}
